import { resolveComponent as _resolveComponent, createVNode as _createVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, Transition as _Transition, withCtx as _withCtx, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_an_loader = _resolveComponent("an-loader")!
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_an_loader, {
      height: 3,
      overlayOpacity: 0.1,
      color: "#ffb86c"
    }, null, 8, ["overlayOpacity"]),
    _createVNode(_component_router_view, null, {
      default: _withCtx(({Component}) => [
        _createVNode(_Transition, {
          name: "slide",
          mode: "out-in"
        }, {
          default: _withCtx(() => [
            (_openBlock(), _createBlock(_resolveDynamicComponent(Component), {
              key: _ctx.$route.path
            }))
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    })
  ], 64))
}