type UIFuncMode = 'theme' | 'mode' | any
type UISetting = string | Blob | File
type MDFUnc = {
  ui: (element?: UIFuncMode, setting?: UISetting) => Promise<string> | string
}
type MDWindow = Window & MDFUnc
const w = (window as Window) as MDWindow
// https://github.com/beercss/beercss/blob/main/docs/SETTINGS.md
const theme = async (setting?: UISetting): Promise<string> => await w.ui('theme', setting)
const mode = (setting?: string): string => w.ui('mode', setting) as string
const ui = (mode: string, setting?: string) => w.ui(mode, setting) as string

export { mode, theme, ui }