import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import InfoFunc from '../views/InfoFunc.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:catchAll(.*)',
    name: 'infofunc',
    component: InfoFunc
  },
  {
    path: '/info22',
    name: 'info22',
    component: () => import(/* webpackChunkName: "info22" */ '../views/Info-22.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
