import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import ContentPage from '@/components/ContentPage.vue'
import ContentsInfo22 from '@/2022/ContentsInfo22.vue'
import SearchPane from '@/components/SearchPane.vue'
import TocPane from '@/components/TocPane.vue'

import 'beercss'
import 'material-dynamic-colors'

import 'affolternet-vue3-library/css'
import { loaderService } from 'affolternet-vue3-library'
import { toastService } from 'affolternet-vue3-library'
import { AnLoader } from 'affolternet-vue3-library'
import { AnToasts } from 'affolternet-vue3-library'
import { AnToast } from 'affolternet-vue3-library'

const app = createApp(App)
app.component('ContentPage', ContentPage)
app.component('ContentsInfo22', ContentsInfo22)
app.component('SearchPane', SearchPane)
app.component('TocPane', TocPane)

app.component('AnLoader', AnLoader)
app.component('AnToasts', AnToasts)
app.component('AnToast', AnToast)

app.provide('loaderService', loaderService)
app.provide('toastService', toastService)

app.use(router).mount('#app')
