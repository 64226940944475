import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = ["data-number"]
const _hoisted_2 = { class: "page_side page_side_left" }
const _hoisted_3 = ["src", "id"]
const _hoisted_4 = ["id"]
const _hoisted_5 = { class: "page_side page_side_right" }
const _hoisted_6 = ["src", "id"]
const _hoisted_7 = ["id"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass($setup.content.classes.join(' ')),
    style: _normalizeStyle($setup.styles),
    "data-number": $setup.config.id,
    onClick: _cache[0] || (_cache[0] = ($event: any) => ($setup.doclick($setup.config.id)))
  }, [
    _createElementVNode("div", _hoisted_2, [
      ($setup.config.imgsLoaded)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "responsive",
            src: $props.front,
            id: `page_${String($props.frontFileIdx).padStart(3, '0')}`
          }, null, 8, _hoisted_3))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "boxdrawer",
        id: `bd_${String($props.frontFileIdx).padStart(3, '0')}`,
        style: _normalizeStyle($setup.dimensions),
        ref: "front_drawer"
      }, null, 12, _hoisted_4)
    ]),
    _createElementVNode("div", _hoisted_5, [
      ($setup.config.imgsLoaded)
        ? (_openBlock(), _createElementBlock("img", {
            key: 0,
            class: "responsive",
            src: $props.back,
            id: `page_${String($props.backFileIdx).padStart(3, '0')}`
          }, null, 8, _hoisted_6))
        : _createCommentVNode("", true),
      _createElementVNode("div", {
        class: "boxdrawer",
        id: `bd_${String($props.backFileIdx).padStart(3, '0')}`,
        style: _normalizeStyle($setup.dimensions),
        ref: "back_drawer"
      }, null, 12, _hoisted_7)
    ])
  ], 14, _hoisted_1))
}