import { RouteLocationNormalizedLoaded, Router } from 'vue-router'
import { eventService } from 'affolternet-vue3-library'
import { Box } from '@/models/SearchHit'
import { loaderService } from 'affolternet-vue3-library'

export type ContentConfig = {
  id: number,
  containsClass: (cls: string) => boolean,
  addClass: (cls: string) => void,
  removeClass: (cls: string) => void,
  zIndex: number,
  setnumbers: (w: number, h: number, unit?: string) => void,
  front: string,
  frontIdx: number,
  back: string,
  backIdx: number,
  imgsLoaded: boolean,
  loadImgs: () => void,
  unit: string,
  bookwidth: number,
  bookheight: number,
  drawBox: null | ((front: boolean, bx: Box) => void),
  clearBoxes: null | ((back: boolean) => void),
}

export type BookConfig = {
  width: string,
  left: string,
  bookenv: {
    width: string,
    height: string,
    opacity: string,
  },
  controls: {
    height: string,
    padding: string,
    fontsize: string,
    minwidth: string,
  },
}

class ContentService {
  bookwidth = 312;
  bookheight = 595;
  max_height = 1000;
  controlsfontsize = 14;
  controlspadding = 3;
  pagepadding = 25;
  pageNumberFontSize = 8;
  unit = 'px';
  scale = this.bookwidth / this.bookheight
  pages: Array<ContentConfig> = [];
  router: Router;
  route: RouteLocationNormalizedLoaded;
  book: BookConfig;
  preloadRunning = false
  public get controlsheight(): number {
    return 70;
  }

  constructor() {
    window.addEventListener("resize", this.onresize(this));
    this.onresize(this)();
  }

  public register(contentPage: ContentConfig): boolean {
    const exists = this.pages.filter(p => p.id === contentPage.id)
    if (exists.length < 1) {
      this.pages.push(contentPage)
      return false
    }
    this.pages[this.pages.indexOf(exists[0])] = contentPage
    return true
  }

  public async initialize(router: Router, route: RouteLocationNormalizedLoaded, book: BookConfig) {
    this.router = router
    this.route = route
    this.book = book
    // be sure to have pages
    if (this.pages.length < 1) {
      console.log('Keine Seiten gefunden')
      return
    }
    // be sure to mark the latest page
    this.pages[this.pages.length-1].addClass('page-last')
    this.pages[this.pages.length-1].removeClass('has-pageno')
    // set sizes
    this.setsizes()
  }

  private async preloadInBackground(pgs: ContentConfig[]) {
    const notloaded = pgs.filter(p => !p.imgsLoaded).slice(0, 2)
    const img = []
    for (const pg of notloaded) {
      const prom = pg.loadImgs()
      img.push(prom)
    }
    await Promise.all(img)
    if (pgs.filter(p => !p.imgsLoaded).length >= 0) {
      setTimeout(() => this.preloadInBackground(pgs), 2000)
    }
  }

  public async preloadImg(sideno: number, showloader: () => void) {
    const filtered = this.pages.filter(p => !p.imgsLoaded && p.frontIdx < sideno + 4 && p.frontIdx > sideno - 4)
    const preload = []
    for (const p of filtered) {
      preload.push(p.loadImgs())  
    }
    if (preload.length > 0) {
      showloader()
    }
    return Promise.all(preload)
  }

  public async loadImg(url: string) {
    return new Promise((resolve, reject) => {
      const i = new Image()
      i.addEventListener('load', () => {
        resolve(url)
      })
      i.addEventListener('error', (err) => {
        console.log(err)
        reject(err)
      })
      i.src = url
    })
  }

  public right() {
    let sideno = this.getCurrentSide();
    if (sideno === 0) {
      sideno = 1;
    } else {
      sideno = this.getRightPageNo() * 2;
    }
    this.show(sideno);
  }

  public left() {
    let sideno = this.getCurrentSide();
    if (sideno === 1) {
      sideno = 0;
    } else {
      sideno = this.getRightPageNo() * 2 - 3;
    }
    this.show(sideno);
  }

  public doclick(id: number) {
    if (id === this.getRightPageNo()) {
      this.right();
    } else {
      this.left();
    }
  }

  public async show(sideno = -1, boxes?: Box[]) {
    // console.log('show', sideno)
    // pages contain two sides, .page_side_left and .page_side_right
    // these are the two "sides" of a page
    const current = this.getCurrentSide();
    if (sideno < 0) {
      sideno = current;
    }

    // set to max if page too big
    const maxsideno = this.pages.length * 2 - 2;
    if (sideno > maxsideno)
    {
      sideno = maxsideno;
    }

    // set page to route on click
    if (sideno > 0) {
      this.router.push(`${this.route.path}?page=${sideno}`);
    } else {
      this.router.push(`${this.route.path}`);
    }

    await this.preloadImg(sideno, () => setTimeout(() => eventService.emit('page_loading'), 300))
    if (!this.preloadRunning) {
      this.preloadRunning = true
      setTimeout(() => this.preloadInBackground(this.pages), 500)
    }

    // display book
    setTimeout(() => this.book.bookenv.opacity = '1', sideno === 0 || sideno === maxsideno ? 1000 : 800);

    // for calculations, right page is used:
    const backwards = sideno < current;
    const currentRightPageNo = this.getRightPageNo(current);
    const currentLeftPageNo = currentRightPageNo - 1;
    const rightPageNo = this.getRightPageNo(sideno);
    const leftPageNo = rightPageNo - 1;
    const targetTurnedPageNo = backwards ? rightPageNo : rightPageNo -1;
    const currentTurnedPageNo = backwards ? currentRightPageNo -1 : currentRightPageNo;

    // set classes
    let hasLeft = false;
    let hasRight = false;
    for (const page of this.pages)
    {
      page.zIndex = 996 - page.id;
      page.removeClass('flip-left-to-right');
      page.removeClass('flip-right-to-left');
      page.removeClass('page-open');
      page.removeClass('page-turned');
      if (page.id < leftPageNo) {
        page.addClass('flip-right-to-left');
        page.addClass('page-turned');
      } else if (page.id === leftPageNo) {
        // visible left page
        hasLeft = true;
        page.addClass('page-turned');
        page.addClass('flip-right-to-left');
      } else if (page.id === rightPageNo) {
        // visible right page
        hasRight = true;
        page.addClass('page-open');
        page.addClass('flip-left-to-right');
      } else {
        page.addClass('flip-left-to-right');
      }
    }

    // prepare z-index/sort levels
    // console.log('target', targetTurnedPageNo, 'current', currentTurnedPageNo);
    this.setZIndex(currentTurnedPageNo, 999);
    if (currentTurnedPageNo !== targetTurnedPageNo){
      // this is used if multiple pages have to be turned
      // 500 ms is in the middle of the animation - good place to change
      setTimeout(() => {
        const currentTurnedPage = this.pages[currentTurnedPageNo]
        this.setZIndex(currentTurnedPageNo, 996 - (currentTurnedPage ? currentTurnedPage.id : 0));
        this.setZIndex(targetTurnedPageNo, 999);
      }, 500);
    }
    if (backwards) {
      this.setZIndex(targetTurnedPageNo - 1, 998);
      this.setZIndex(currentRightPageNo, 997);
    } else {
      this.setZIndex(targetTurnedPageNo + 1, 998);
      this.setZIndex(currentLeftPageNo, 997);
    }
    
    // book and z-index settings
    if (hasLeft && hasRight){
      this.book.width = `${2*this.bookwidth}${this.unit}`;
      this.book.left = `0${this.unit}`;
    } else {
      this.book.width = `${this.bookwidth}${this.unit}`;
      this.book.left = hasLeft ? `${this.bookwidth}${this.unit}` : `0${this.unit}`;
    }

    setTimeout(() => {
      this.clearBoxes()
      eventService.emit('page_shown')
      if (boxes && boxes.length > 0) {
        let pageIndex = this.getRightPageNo(sideno)
        const pageNoEven = sideno % 2 === 0
        pageIndex = pageNoEven ? pageIndex - 1 : pageIndex
        const page = this.pages[pageIndex]
        if (!page.drawBox) {
          return
        }
        for (const box of boxes) {
          page.drawBox(!pageNoEven, box)
        }
      }
    }, 500)
  }

  public clearBoxes() {
    for (const p of this.pages) {
      p.clearBoxes?.(true)
      p.clearBoxes?.(false)
    }
  }

  private getCurrentSide(): number {
    const current = this.route.query.page?.toString();
    return parseInt(current || '0');
  }

  public getRightPageNo(sideno?: number): number {
    /*
      booklet-example:
      ----------------
      0       | undefined | 0 front
      1       | 0 back    | 1 front
      2 || 3  | 1 back    | 2 front
      4 || 5  | 2 back    | 3 front
      6 || 7  | 3 back    | 4 front
      8       | 4 back    | undefined
    */
    if (sideno === undefined) {
      sideno = this.getCurrentSide();
    }
    let rightPageNo = sideno;
    const sideNoIsEven = rightPageNo % 2 !== 0;
    if (sideNoIsEven && rightPageNo > 1) {
      rightPageNo--;
    }
    if (rightPageNo > 1) {
      rightPageNo = rightPageNo / 2 + 1;
    }
    return rightPageNo;
  }

  private setZIndex(pageNo: number, zIndex: number): void {
    const page = this.pages[pageNo];
    if (page) {
      page.zIndex = zIndex; 
    }
  }

  private setsizes(svc?: ContentService) {
    // set width and height
    svc = svc || this;
    if (svc.book) {
      // bookenv
      svc.book.bookenv.width = `${2 * svc.bookwidth}${svc.unit}`
      svc.book.bookenv.height = `${svc.bookheight + svc.controlsheight}${svc.unit}`
      // controls
      svc.book.controls.height = `${svc.controlsheight}${svc.unit}`
      svc.book.controls.padding = `${svc.controlspadding}${svc.unit}`
      //pages
      for (const p of svc.pages) {
        p.setnumbers(svc.bookwidth, svc.bookheight, svc.unit)
      }
      svc.show()
    }
  }

  private onresize(svc: ContentService) {
    return function() {
      const currentheight = document.documentElement.clientHeight - svc.controlsheight;
      svc.bookheight = currentheight > svc.max_height ? svc.max_height : currentheight;
      svc.bookwidth = svc.bookheight * svc.scale;
      svc.setsizes(svc);  
    }
  }
}

export const contentService = new ContentService();
