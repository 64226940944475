import { SearchResult } from 'minisearch'

export type Box = [left: number, top: number, right: number, bottom: number]

export class SearchHit {

  private wordsback = 2
  private wordsnext = 2
  public page = -1
  public words: string[] = []
  public matches: string[] = []
  public txt = ''
  public resultsview = ''
  public boxes: Box[] = []
  public matchingboxes: Box[] = []
  public category: string
  public title: string

  constructor(r?: SearchResult) {
    if (!r) {
      this.resultsview = 'keine Treffer gefunden'
      return
    }
    this.page = r.id - 2
    this.words = r.words
    this.boxes = r.boxes
    this.matches = Object.keys(r.match)
    this.txt = r.text
    this.category = r.category
    this.title = r.title
    this.createResultsView()
  }

  private createResultsView() {
    this.resultsview = ''
    const foundParts: string[] = []
    for (let i = 0; i < this.words.length; i++) {
      const w = this.words[i]
      let ismatch = false
      for (const m of this.matches) {
        if (w.toLowerCase().startsWith(m)) {
          ismatch = true
          continue
        }
      }
      if (!ismatch) {
        continue
      }
      // word found
      this.matchingboxes.push(this.boxes[i])
      let start = i - this.wordsback
      let end = i + this.wordsnext
      if (start < 0) {
        start = 0
      }
      if (end > this.words.length - 1) {
        end = this.words.length -1
      }
      foundParts.push(`...${this.words.slice(start, end).join(' ').trim()}...`)
    }
    this.resultsview = foundParts.join('') || this.txt
  }
}
